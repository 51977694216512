#welcome {
  .min-vh-100 {
    .col-12 {
      &:first-child {
        img {
          // margin-top: #{$spacer * 3} !important;
          margin-top: #{$spacer * 1.5} !important;
          max-height: 75%;
        }
      }
    }
  }

  #strap {
    img {
      max-width: 230px;
    }
  }

  #circuit {
    width: 100%;
    height: auto;
  }

  #grid-source {
    height: auto;
  }

  #headphones {
    // height: 50px;
    // margin-bottom: #{$spacer} !important;
    height: 30px;
    margin-bottom: #{$spacer / 2} !important;
  }

  i { 
    // font-size: #{$font-size-base * 1.5};
    font-size: #{$font-size-base * 1};

    &#btn-play {
      // font-size: #{$font-size-base * 3};
      font-size: #{$font-size-base * 2};
    }
  }

  #map-holder {
    #pick {
      img {
        width: 75%;
      }
    }
  }
}

@media (min-height: 800px) {
  #welcome {
    .min-vh-100 {
      .col-12 {
        &:first-child {
          img {
            margin-top: #{$spacer * 3} !important;
          }
        }
      }
    }

    #headphones {
      height: 40px;
      margin-bottom: #{$spacer} !important;
    }

    i { 
      font-size: #{$font-size-base * 1.25};

      &#btn-play {
        font-size: #{$font-size-base * 2.5};
      }
    }
  }

}

@media (min-height: 1000px) {
  #welcome {
    #headphones {
      height: 50px;
    }

    i { 
      font-size: #{$font-size-base * 1.5};

      &#btn-play {
        font-size: #{$font-size-base * 3};
      }
    }
  }

}


@include media-breakpoint-up(sm) {
  #welcome {
    #grid-source {
      width: 75%;
    }
  }
}