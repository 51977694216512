#welcome {
  .min-vh-100 {
    .col-12 {
      &:first-child {
        img {
          max-height: 85%;
        }
      }

      &:nth-child(2) {
        height: 55vh;
      }
      
      &:last-child {
        height: 25vh;
      }
    }
  }
}