// @media (min-width: 576px) { ... }
// @media (min-width: 768px) { ... }
// @media (min-width: 992px) { ... }
// @media (min-width: 1200px) { ... }

html, body {
  background: $black;
  overflow-x: hidden;
}

//  https://github.com/fregante/iphone-inline-video#usage
.IIV::-webkit-media-controls-play-button,
.IIV::-webkit-media-controls-start-playback-button {
  opacity: 0;
  pointer-events: none;
  width: 5px;
}

#welcome {
  background: transparent url('../img/circuit-2-1920x1080.png') no-repeat center center;
  background-size: auto 80%;

  #bg {
    top: 0;
    left: 0;
    opacity: 1;
    background: transparent url('../img/background-3-1920x1080.png') no-repeat center center;
    background-size: auto 80%;
    @include transition(opacity 200ms cubic-bezier(.55,.09,.68,.53));
  }

  #track-video {
    height: 80%;
    opacity: 0;
    z-index: 2;
    @include transition(opacity 1s cubic-bezier(.55,.09,.68,.53));
  }

  #map-holder {
    z-index: 1;
    opacity: 0;
    @include transition(opacity 1s linear);

    #pick {
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      // animation: pick 4s infinite;
      // @include transition(opacity 2s linear);


      img {
        width: 25%;
        max-width: 585px;
      }
    }
  }

  #circuit {
    height: 100%;
    width: auto;
  }

  #grid-source {
    height: 80%;

    #grid-overlay {
      left: 0;
      top: 0;

      .hover { background-color: rgba(255,0,0,0.5) !important; }
      .selected { background-color: rgba(255,0,0,0.9); }
    }
  }

  #controls {
    bottom: 0;
    left: 0;
    right: 0;
  }

  #indicator, #indicator-2, #indicator-3 {
    background: $red;
    border-radius: 0;
    box-sizing: border-box;
    color: #fff;
    font-size: 1.6em;
    height: 12em;
    overflow: hidden;
    text-shadow:0.1em 0.1em 0.1em #000;
    vertical-align: top;
    width: 12em;    
  }

  #indicator {
    animation: pulse 0.8s ease-out infinite;
  }

  #indicator-2 {
    opacity: 0.6;
    animation: fade 2s;
    animation-fill-mode: forwards;
  }

  @keyframes pulse {
    50% {
      box-shadow: 0 0 0 1px $red, 0 0 0 3px rgba(255, 255, 255, 0.75);
    }
    100% {
      box-shadow: 0 0 0 3px $red, 0 0 0 6px rgba(255, 255, 255, 0);
    }
  }

  @keyframes fade {
    from {
      opacity: 0.6;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes pick {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  // https://codepen.io/quadbaup/pen/JXXRWL
  // #indicator {
  //   left: 0;
  //   top: 0;
  //   background: $red;
  //   // border-radius: 0.6em;
  //   box-sizing: border-box;
  //   color: #fff;
  //   font-size: 1.6em;
  //   height: 12em;
  //   overflow: hidden;
  //   text-shadow:0.1em 0.1em 0.1em #000;
  //   vertical-align: top;
  //   width: 12em;    
  //   animation: pulse 0.8s ease-out infinite;
  // }

  // @keyframes pulse {
  //   50% {
  //     box-shadow: 0 0 0 1px $red, 0 0 0 3px rgba(255, 255, 255, 0.75);
  //   }
  //   100% {
  //     box-shadow: 0 0 0 3px $red, 0 0 0 6px rgba(255, 255, 255, 0);
  //   }
  // }

  .min-vh-100 {
    .col-12 {
      &:first-child {
        height: 20vh;

        img {
          max-width: 100%;
          max-height: 85%;
        }
      }
      
      &:nth-child(2) {
        height: 60vh;
      }
      
      &:last-child {
        height: 20vh;
      }
    }
  }

  #strap {
    @include transition(opacity 100ms cubic-bezier(.55,.09,.68,.53));

    img {
      width: 75%;
      max-width: 200px;
    }
  }

  #headphones {
    width: auto;
    height: 25px;
  }

  .progress {
    width: 200px;
    height: 2px;
    overflow: visible;

    i {
      font-size: #{$font-size-base * 0.375};
      margin-top: #{$spacer * -(1 / 8)};
      cursor: default;
    }
  }

  i { 
    font-size: #{$font-size-base * 1};
    color:  $gray-300;
    cursor: pointer;

    &#btn-play {
      font-size: #{$font-size-base * 1.5};
    }
  }

  .col-auto {
    span {
      color: $red;
    }
  }

  #info, #enter {
    color: $red;
    text-transform: uppercase;
    font-weight: 600;
  }

  #enter {
    // border-left: solid 2px $red;
    &.go {
      background-color: $red;
      color: $white;
    }
  }
}

@include media-breakpoint-up(lg) {
  #welcome {
    .min-vh-100 {
      .col-12 {
        &:first-child {
          img {
            margin-top: $spacer !important;
            max-height: 75%;
          }
        }
      }
    }

    #strap {
      img {
        max-width: 230px;
      }
    }

    #headphones {
      height: 50px;
      margin-bottom: #{$spacer} !important;
    }

    i { 
      font-size: #{$font-size-base * 1.5};

      &#btn-play {
        font-size: #{$font-size-base * 3};
      }
    }
  }
}

.btn-primary {
  &:hover, &:focus {
    background-color: $white;
    border-color: $white;
    color: $red;
  }
}