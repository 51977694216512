.btn {
  border-radius: 0;
  background: none;
  border: none;
  border-bottom: solid 2px $white;
  padding: 0 8px;
  font-weight: 600;
}

.invalid-feedback {
  color: $white;
  font-weight: 600;
}